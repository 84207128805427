import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withFedopsLogger } from '@wix/yoshi-flow-editor';
import { logWidgetVidPlayStart } from '../../utils/bi/widget-common-events';
import VideoPlayer from '../../player-widget/components/new-player/player';
import { logBi } from '../../worker/actions/bi';
import { getBuyButtonDisplayType } from '@wix/wix-vod-shared/dist/src/common/utils/overlay-buttons-utils';
import { canAccessFullVideo } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { convertSToMS } from '@wix/wix-vod-shared/dist/src/widget/utils/time';
import { shouldFetchUrls } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/fetch-urls';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import { isVideoOverQuota } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/quota';
import getVideoUrls from '../../selectors/get-video-urls';
import { getAllSettings } from '../../selectors/app-settings';
import { getCurrentSiteUser } from '../../selectors/current-site-user';
import { isAutoplayed } from '../../selectors/player';
import { fetchVideoUrls } from '../../redux/actions/video-urls/fetch';
import { fetchVideoCards } from '../../redux/actions/video-cards/get';
import { cleanupVideoCards } from '../../redux/actions/video-cards/cleanup';
import { isVideoCardsEnabled } from '../../selectors/video-cards';
import { STATS_EVENTS } from '../../constants/stats/events';
import translations from '@wix/wix-vod-shared/dist/src/common/i18n/translations';
import { ERRORS } from 'playable/dist/src/constants';
import { withHandlersAndState } from '../../containers/withHandlersAndState';
import { isMobile } from '../../selectors/form-factor';

const mapStateToProps = (state, props) => {
  const video = _.get(props, 'videoItem', {});

  return {
    videoUrls: getVideoUrls(state, props),
    appSettings: getAllSettings(state),
    currentSiteUser: getCurrentSiteUser(state),
    isVideoCardsEnabled: isVideoCardsEnabled(state, video),
    isOverQuota: isVideoOverQuota(video),
    isLive: isLiveVideo(video),
    isAutoplayed: isAutoplayed(state),
    isMobile: isMobile(state),
  };
};

const mapDispatchToProps = {
  fetchVideoUrls,
  fetchVideoCards,
  cleanupVideoCards,
  logBi,
  logWidgetVidPlayStart,
};

class PlayerWithStatAndBI extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    videoUrls: PropTypes.object,
    videoItem: PropTypes.object,
    // remove after new player
    channelData: PropTypes.object,
    size: PropTypes.object,
    simple: PropTypes.bool,
    mobileMode: PropTypes.bool,
    overlay: PropTypes.any,
    onFullScreenChanged: PropTypes.func,
    onPlayStart: PropTypes.func,
    onEnded: PropTypes.func,
    preload: PropTypes.string,
    handlers: PropTypes.object,
    fetchVideoUrls: PropTypes.func,
    appSettings: PropTypes.object,
    canShowChannelCover: PropTypes.bool,
    mainOrThumbnailLocation: PropTypes.string,
    muted: PropTypes.bool,
    loop: PropTypes.bool,
    showInitialOverlay: PropTypes.bool,
    currentSiteUser: PropTypes.object,
    onMemberSignUp: PropTypes.func,
    currentTime: PropTypes.number,

    abortPlayVideo: PropTypes.func,
    onPause: PropTypes.func,
    onResume: PropTypes.func,
    onSubscribeClick: PropTypes.func,
    onPurchaseClick: PropTypes.func,
    onRentClick: PropTypes.func,
    isVideoCardsEnabled: PropTypes.bool,
    fetchVideoCards: PropTypes.func,
    onPlayRequest: PropTypes.func,
    shouldLoadVideo: PropTypes.bool,
    isOverQuota: PropTypes.bool,
    isLive: PropTypes.bool,
    cleanupVideoCards: PropTypes.func,
    isInLightbox: PropTypes.bool,
    isAutoplayed: PropTypes.bool,
    isVideoPlayRequested: PropTypes.bool,
    isVideoPauseRequested: PropTypes.bool,
    isVideoPlaying: PropTypes.bool,
    isVideoPaused: PropTypes.bool,
    isVideoPlayingOptimistic: PropTypes.bool,
    isVideoPausedOptimistic: PropTypes.bool,
    isVideoPlayAborted: PropTypes.bool,
    isMobile: PropTypes.bool,
    fillAllSpace: PropTypes.bool,
    handleVideoEnd: PropTypes.func,
  };

  static defaultProps = {
    abortPlayVideo: _.noop,
    onFullScreenChanged: _.noop,
    onPlayStart: _.noop,
    onEnded: _.noop,
    onMemberSignUp: _.noop,
    onSubscribeClick: _.noop,
    onPurchaseClick: _.noop,
    onRentClick: _.noop,
    handleVideoEnd: _.noop,
    mainOrThumbnailLocation: 'main',
    simple: false,
    mobileMode: false,
    onPlayRequest: _.noop,
    shouldLoadVideo: true,
    isLive: false,
  };

  componentDidMount() {
    this.checkVideoUrls(this.props);
    this.updateVideoCards(this.props.videoItem);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkVideoUrls(nextProps);
  }

  componentDidUpdate(prevProps) {
    const isVideoCardsEnabledChanged =
      !prevProps.isVideoCardsEnabled && this.props.isVideoCardsEnabled;
    const isVideoIdChanged = prevProps.videoItem.id !== this.props.videoItem.id;

    if (isVideoIdChanged || isVideoCardsEnabledChanged) {
      this.updateVideoCards(this.props.videoItem);
    }
  }

  reloadCounts = {};

  checkVideoUrls({
    fetchVideoUrls,
    videoUrls,
    channelData,
    videoItem,
    currentSiteUser,
    shouldLoadVideo,
  }) {
    if (
      shouldLoadVideo &&
      shouldFetchUrls({ videoUrls, channelData, videoItem, currentSiteUser })
    ) {
      fetchVideoUrls(videoItem);
    }
  }

  updateVideoCards(videoItem) {
    const {
      isVideoCardsEnabled,
      fetchVideoCards,
      shouldLoadVideo,
      cleanupVideoCards,
    } = this.props;

    if (!shouldLoadVideo || !videoItem) {
      return;
    }

    if (!isVideoCardsEnabled || !videoItem.hasCards) {
      cleanupVideoCards();
    } else {
      fetchVideoCards(videoItem.listId, videoItem.id);
    }
  }

  get isUserLogged() {
    const { currentSiteUser } = this.props;

    return Boolean(currentSiteUser);
  }

  reloadVideoUrls = (error) => {
    if (!error) {
      return;
    }

    const { fetchVideoUrls, videoItem } = this.props;
    const { id } = videoItem;

    this.reloadCounts[id] = this.reloadCounts[id] || 0;
    this.reloadCounts[id] += 1;

    if (this.reloadCounts[id] > 1) {
      return;
    }

    switch (error.errorType) {
      case ERRORS.MANIFEST_LOAD:
      case ERRORS.LEVEL_LOAD:
      case ERRORS.CONTENT_LOAD:
        fetchVideoUrls(videoItem, true);
        break;
      default:
        break;
    }
  };

  onPlayStart = () => {
    this.props.onPlayStart();
    this.sendVidStartPlayBI();
  };

  onEnded = () => {
    const {
      onEnded,
      handlers,
      channelData,
      videoItem,
      currentSiteUser,
      handleVideoEnd,
    } = this.props;
    let event;

    this.sendVidPlayedBI();

    if (canAccessFullVideo(channelData, videoItem, currentSiteUser)) {
      event = STATS_EVENTS.VIDEO_PLAY_COMPLETE;
    } else {
      event = STATS_EVENTS.VIDEO_PLAY_TRAILER;
    }

    handlers.reportStats(event, {
      channel: channelData,
      videoItem,
    });

    onEnded();
    handleVideoEnd();
  };

  sendVidStartPlayBI() {
    const {
      mainOrThumbnailLocation: button,
      channelData,
      videoItem,
    } = this.props;

    this.props.logWidgetVidPlayStart({
      channelData,
      videoItem,
      button,
    });
  }

  sendVidPlayedBI() {
    const {
      mainOrThumbnailLocation: button,
      channelData,
      videoItem: { id, durationSec },
      isLive,
      isAutoplayed,
    } = this.props;

    this.props.logBi('widget.vid.played', {
      channelID: channelData.id,
      videoID: id,
      button,
      isLive,
      duration: convertSToMS(durationSec),
      isAutoplay: isAutoplayed,
    });
  }

  sendBIForVideoInfoShow = () => {
    this.sendBI('widget.vid.info.clicked');
  };

  sendBIForPurchaseRequested = () => {
    this.sendBuyBI('widget.vid.buy.clicked');
  };

  sendBIForShareRequested = (networkName) => {
    const { handlers, channelData, videoItem } = this.props;

    handlers.reportStats(STATS_EVENTS.VIDEO_SHARE, {
      channel: channelData,
      videoItem,
      network: {
        name: networkName,
      },
    });

    this.sendBI('widget.vid.share.clicked', {
      button: networkName,
    });
  };

  sendBI(name, params) {
    const { channelData, videoItem } = this.props;
    const { id, videoSource } = videoItem;

    this.props.logBi(
      name,
      _.assign({}, params, {
        channelID: channelData.id,
        videoID: id,
        videoType: videoSource,
      }),
    );
  }

  sendBuyBI(name) {
    const {
      videoItem,
      channelData,
      mainOrThumbnailLocation: button,
    } = this.props;

    const buyType = getBuyButtonDisplayType(channelData, videoItem);

    this.sendBI(name, {
      buyType,
      button,
    });
  }

  sendBIForFullScreenChanged = (isFullScreen) => {
    const { channelData, videoItem } = this.props;

    if (isFullScreen) {
      this.props.logBi('widget.vid.fullScreen.entered', {
        channelID: channelData.id,
        videoID: videoItem.id,
        origin: 'on_site',
      });
    }

    this.props.onFullScreenChanged(isFullScreen);
  };

  onPurchaseRequest = () => {
    const { onPurchaseClick } = this.props;

    this.sendBIForPurchaseRequested();
    onPurchaseClick();
  };

  onRentRequest = () => {
    const { onRentClick } = this.props;

    this.sendBIForPurchaseRequested();
    onRentClick();
  };

  onSubscribeRequest = () => {
    const { onSubscribeClick } = this.props;

    this.sendBIForPurchaseRequested();
    onSubscribeClick();
  };

  render() {
    const { size, isOverQuota, fillAllSpace } = this.props;

    const props = _.omit(this.props, 'size', 'handlers');

    return (
      <VideoPlayer
        {...props}
        {...size}
        isFetchDisabled
        translations={translations.getAll()}
        onPlayStart={this.onPlayStart}
        onEnded={this.onEnded}
        onError={this.reloadVideoUrls}
        onPurchaseRequest={this.onPurchaseRequest}
        onRentRequest={this.onRentRequest}
        onSubscribeRequest={this.onSubscribeRequest}
        onShareRequest={this.sendBIForShareRequested}
        onVideoInfoShow={this.sendBIForVideoInfoShow}
        onFullScreenChanged={this.sendBIForFullScreenChanged}
        isOverQuota={isOverQuota}
        fillAllSpace={fillAllSpace}
      />
    );
  }
}

export default withFedopsLogger(
  withHandlersAndState(
    connect(mapStateToProps, mapDispatchToProps)(PlayerWithStatAndBI),
  ),
);
