import { IHttpClient } from '@wix/http-client';
import { PrivateWidgetsService } from '@wix/wix-vod-api/dist/src/private/widgets/widgets';
import { PrivateSettingsService } from '@wix/wix-vod-api/dist/src/private/settings/settings';

export let privateApi: {
  widgets: PrivateWidgetsService;
  settings: PrivateSettingsService;
};

export function initPrivateServices(httpClient: IHttpClient, baseUrl?: string) {
  privateApi = {
    widgets: new PrivateWidgetsService(httpClient),
    settings: new PrivateSettingsService(httpClient, baseUrl),
  };
}
