export enum FedopsInteractionsNames {
  CASHIER_MODAL_LOAD = 'CASHIER_MODAL_LOAD',
  CASHIER_PAYMENT_PAGE_LOAD = 'CASHIER_PAYMENT_PAGE_LOAD',
  CASHIER_PAYMENT_COMPLETE_PAGE_LOAD = 'CASHIER_PAYMENT_COMPLETE_PAGE_LOAD',
  GET_WIDGET_DATA = 'GET_WIDGET_DATA',
  MOBILE_OPEN_VIDEO_VIEW = 'MOBILE_OPEN_VIDEO_VIEW',

  // this interactions should be emitted in many places if we need it
  // MOBILE_LIGHTBOX_APP_LOADED = 'MOBILE_LIGHTBOX_APP_LOADED',
  // DESKTOP_LIGHTBOX_APP_LOADED = 'DESKTOP_LIGHTBOX_APP_LOADED',
}
