import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { createPublicPurchaseService } from '@wix/wix-vod-api/dist/src/public/purchase';
import { PublicVideoApiService } from '@wix/wix-vod-api/dist/src/public/video/video';
import { PublicChannelVideosService } from '@wix/wix-vod-api/dist/src/public/channel-videos/channel-videos';
import { PublicWidgetsService } from '@wix/wix-vod-api/dist/src/public/widgets/widgets';
import { IHttpClient } from '@wix/http-client';
import { PublicPurchaseService } from '@wix/wix-vod-api/dist/src/public/purchase/purchase';
import { YoutubeVideosService } from '@wix/wix-vod-api/dist/src/public/youtube/youtube-videos-service';
import { PublicVideoCards } from '@wix/wix-vod-api-site-based/dist/src/public/services/video-cards/video-cards';
import { PublicChannel } from '@wix/wix-vod-api-site-based/dist/src/public/services/channel/channel';
import { YoutubeService } from '@wix/wix-vod-api-site-based/dist/src/public/services/youtube/youtube';
import { PublicChannelVideos } from '@wix/wix-vod-api-site-based/dist/src/public/services/channel-videos/channel-videos';

interface PublicAPI {
  purchase: PublicPurchaseService;
  video: PublicVideoApiService;
  channelVideos: PublicChannelVideosService;
  widgets: PublicWidgetsService;
  youtubeVideosService: YoutubeVideosService;
  publicWidgetsService: PublicWidgetsService;
  // site-based
  oldPublicVideoCardsService: PublicVideoCards;
  oldPublicChannelService: PublicChannel;
  oldYoutubeService: YoutubeService;
  oldPublicChannelVideos: PublicChannelVideos;
}

let publicApi: PublicAPI | undefined;

export function getPublicApi(): PublicAPI {
  if (publicApi === undefined) {
    throw Error(
      'public Api has not yet been initialized, call `initPublicServices` first',
    );
  }
  return publicApi;
}

const initPublicServicesV2 = (
  httpClient: IHttpClient,
  instance: string,
  baseUrl: string,
) => {
  const isV3 = isGraphAPIEnabled();
  publicApi = {
    purchase: createPublicPurchaseService(isV3, instance, baseUrl),
    video: new PublicVideoApiService(httpClient),
    channelVideos: new PublicChannelVideosService(httpClient),
    widgets: new PublicWidgetsService(httpClient),
    youtubeVideosService: new YoutubeVideosService(httpClient),
    publicWidgetsService: new PublicWidgetsService(httpClient),
    oldPublicVideoCardsService: new PublicVideoCards(httpClient),
    oldPublicChannelService: new PublicChannel(httpClient),
    oldYoutubeService: new YoutubeService(httpClient),
    oldPublicChannelVideos: new PublicChannelVideos(httpClient),
  };
};

const initPublicServicesV3ToV2Mapping = (
  httpClient: IHttpClient,
  instance: string,
  baseUrl: string,
  v3MappingBaseUrl: string,
) => {
  const isV3 = isGraphAPIEnabled();

  publicApi = {
    purchase: createPublicPurchaseService(isV3, instance, baseUrl),
    video: new PublicVideoApiService(httpClient, v3MappingBaseUrl),
    channelVideos: new PublicChannelVideosService(httpClient, v3MappingBaseUrl),
    widgets: new PublicWidgetsService(httpClient),
    youtubeVideosService: new YoutubeVideosService(httpClient),
    publicWidgetsService: new PublicWidgetsService(httpClient),
    oldPublicVideoCardsService: new PublicVideoCards(
      httpClient,
      v3MappingBaseUrl,
    ),
    oldPublicChannelService: new PublicChannel(httpClient, v3MappingBaseUrl),
    oldYoutubeService: new YoutubeService(httpClient),
    oldPublicChannelVideos: new PublicChannelVideos(
      httpClient,
      v3MappingBaseUrl,
    ),
  };
};

type V3ToV2MappingOptions =
  | { isMappingOn: true; v3ToV2MappingBaseUrl: string }
  | { isMappingOn: false };

export function initPublicServices(
  httpClient: IHttpClient,
  instance: string,
  baseUrl: string,
  v3ToV2MappingOptions: V3ToV2MappingOptions = { isMappingOn: false },
) {
  if (v3ToV2MappingOptions.isMappingOn) {
    initPublicServicesV3ToV2Mapping(
      httpClient,
      instance,
      baseUrl,
      v3ToV2MappingOptions.v3ToV2MappingBaseUrl,
    );
  } else {
    initPublicServicesV2(httpClient, instance, baseUrl);
  }
}
